<template>
  <div class="details">
    <div class="title">{{data.cname}}</div>
    <div class="line" v-if="data.cname">
      <div class="line-title">【 疾病名字 】</div>
      <div class="line-text" v-html="data.cname"></div>
    </div>
    <div class="line" v-if="data.ename">
      <div class="line-title">【 英文名称 】</div>
      <div class="line-text" v-html="data.ename"></div>
    </div>
    <div class="line" v-if="data.alias">
      <div class="line-title">【 别&nbsp&nbsp&nbsp&nbsp&nbsp名 】</div>
      <div class="line-text" v-html="data.alias"></div>
    </div>
    <div class="line" v-if="data.category_path">
      <div class="line-title">【 类&nbsp&nbsp&nbsp&nbsp&nbsp别 】</div>
      <div class="line-text" v-html="data.category_path"></div>
    </div>
    <div class="column" v-if="data.reagent_type">
      <div class="line-title">【 剂&nbsp&nbsp&nbsp&nbsp&nbsp量 】</div>
      <div class="line-text" v-html="data.reagent_type"></div>
    </div>
    <div class="column" v-if="data.drug_principle">
      <div class="line-title">【 药理作用 】</div>
      <div class="line-text" v-html="data.drug_principle"></div>
    </div>
    <div class="column" v-if="data.drug_move">
      <div class="line-title">【 药&nbsp动&nbsp学 】</div>
      <div class="line-text" v-html="data.drug_move"></div>
    </div>
    <div class="column" v-if="data.indications">
      <div class="line-title">【 适&nbsp应&nbsp证 】</div>
      <div class="line-text" v-html="data.indications"></div>
    </div>
    <div class="column" v-if="data.contraindication">
      <div class="line-title">【 禁&nbsp忌&nbsp证 】</div>
      <div class="line-text" v-html="data.contraindication"></div>
    </div>
    <div class="column" v-if="data.announcement">
      <div class="line-title">【 注意事项 】</div>
      <div class="line-text" v-html="data.announcement"></div>
    </div>
    <div class="column" v-if="data.maladjustment">
      <div class="line-title">【 不良反应 】</div>
      <div class="line-text" v-html="data.maladjustment"></div>
    </div>
    <div class="column" v-if="data.usage_amount">
      <div class="line-title">【 用法用量 】</div>
      <div class="line-text" v-html="data.usage_amount"></div>
    </div>
    <div class="column" v-if="data.expert_comment">
      <div class="line-title">【 专家点评 】</div>
      <div class="line-text" v-html="data.expert_comment"></div>
    </div>
    <div class="column" v-if="data.medcine_coaction">
      <div class="line-title">【 药物相关作用 】</div>
      <div class="line-text" v-html="data.medcine_coaction"></div>
    </div>
    <div class="line" v-if="data.download">
      <div class="line-title">【 操&nbsp&nbsp&nbsp&nbsp&nbsp作 】</div>
      <div class="line-text">
        <a href="" @click.prevent="onDownload(data, '2')">下 载</a>
      </div>
    </div>
    <div class="foot-details" v-if="data.disease">
      <div class="line-title">【 相关疾病 】</div>
      <div class="line-text">
        <ul v-html="data.disease"></ul>
      </div>
    </div>

  </div>
</template>

<script>
  import mixins from '../../mixins'
  export default {
    name: "medicineDisease",
    mixins: [
      mixins
    ],
    data(){
      return {
        type: '2',
      }
    },
    created() {
      if (this.$route.query.id || this.$route.query.ID){
        this.getData({ id: this.$route.query.id || this.$route.query.ID, type: this.type })
      }
    }
  }
</script>
